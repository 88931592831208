import React from "react";
import AOS from "aos";
AOS.init();

const hero = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Resume</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="row gh-5">
        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Experiences</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2020 - now</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Frontend Team Lead</span>
                  <a
                    className="text-decoration-none"
                    href="https://www.mtsbank.ru/chastnim-licam/karti/credit-mts-cashback"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="d_company">MTC Bank</span>
                  </a>
                  Development Bank web site
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2016 - 2020</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Fullstack Team Lead</span>
                  <a
                    className="text-decoration-none"
                    href="https://kamagames.ru"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="d_company">KAMA Games Studio</span>
                  </a>
                  Development internal web services
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2015 - 2016</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Fullstack Developer</span>
                  <a
                    className="text-decoration-none"
                    href="https://santehnika-online.ru/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="d_company">Santehnika Online</span>
                  </a>
                  Development e-commerce web service
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2013 - 2015</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Fullstack Team Lead</span>
                  <a
                    className="text-decoration-none"
                    href="https://www.agima.ru"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="d_company">AGIMA</span>
                  </a>
                  Web Studio
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2012 - 2013</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Web developer</span>
                  <a
                    className="text-decoration-none"
                    href="http://evoinfinity.ru/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="d_company">Evoinfinity</span>
                  </a>
                  Startup
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Education</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2020 - now</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Psychologist</span>
                  <a
                    className="text-decoration-none"
                    href="https://gestalt.ru/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="d_company">Moscow Gestalt Institute</span>
                  </a>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2007 - 2012</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Math and Informatic, Programmer
                  </span>
                  <a
                    className="text-decoration-none"
                    href="https://dalrybvtuz.ru/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="d_company">
                      The Far Eastern State Technical Fisheries University
                      (FESTFU)
                    </span>
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="spacer-double"></div>
      </div>
    </div>
  );
};

export default hero;
