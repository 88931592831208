import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from "../layout/Navbar";
import Hero from "../section/hero";
import About from "../section/about";
// import Blockquote from "../section/blockquote";
// import Whatido from "../section/whatido";
// import Gallery from "../section/gallery";
import Resume from "../section/resume";
// import Counter from "../section/counter";
// import Blog from "../section/blog";
import Contact from "../section/contact";
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from "../layout/ScrollToTop";
import Particle from "../layout/Particle";
import { createGlobalStyle } from "styled-components";

const image1 = `./img/background/${Math.ceil(Math.random() * 5)}.jpg`;

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

function home() {
  return (
    <>
      <GlobalStyles />
      <Preloader />
      <div className="home">
        <header id="header-wrap">
          <Navbar />
        </header>

        {/* HERO */}
        <section id="hero-area" className="bg-bottom py-0">
          <Parallax bgImage={image1} strength={300}>
            <Particle />
            <Hero />
            <Link smooth spy to="about">
              <span className="mouse transition" id="fly">
                <span className="scroll"></span>
              </span>
            </Link>
          </Parallax>
        </section>

        {/* ABOUT */}
        <section id="about" className="pb-0">
          <About />
        </section>

        {/* Gallery */}
        <section id="resume" className="pb-0">
          <Resume />
          {/* <Counter /> */}
        </section>

        {/* Gallery */}
        {/* <section id="blog" className="pb-0">
          <Blog />
        </section> */}

        {/* contact */}
        <section id="contact" className="pb-0">
          <Contact />
          {/* <Footer /> */}
        </section>

        <div className="float-text">
          <div className="de_social-icons">
            <a href="https://riddick6600.t.me" target="_blank" rel="noreferrer">
              <span className="buton">
                <i className="fa fa-telegram fa-lg"></i>
              </span>
            </a>
            <a
              href="https://hh.ru/resume/b03e7570ff0130f6b30039ed1f4e7069433448"
              target="_blank"
              rel="noreferrer"
            >
              <span className="buton">
                <i className="fa fa-briefcase fa-lg"></i>
              </span>
            </a>
            {/* <a href="tel:+79998231200">
              <span className="buton">
                <i className="fa fa-phone fa-lg"></i>
              </span>
            </a> */}
            <a href="mailto:riddick6600@mail.ru">
              <span className="buton">
                <i className="fa fa-envelope fa-lg"></i>
              </span>
            </a>
          </div>
          <span>Contact Me</span>
        </div>
      </div>
      <ScrollToTopBtn />
    </>
  );
}

export default home;
