import React from "react";
import { Parallax } from "react-parallax";
import AOS from "aos";
AOS.init();

const image1 = "./img/background/5.jpg";
const contact = () => {
  return (
    <div className="section bg-top bg-bottom py-0">
      <Parallax className="py-5" bgImage={image1} strength={400}>
        <div className="py-5 position-relative">
          <div className="container section-contact">
            <div className="row">
              <div
                className="col-md-12 text-center"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="0"
                data-aos-duration="1000"
                data-aos-easing="ease"
              >
                <h2>Contacts</h2>
                <div className="space-border"></div>
              </div>
            </div>
            <div
              className="col-lg-8 offset-lg-2"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
            >
              <div className="row text-center">
                <div
                  className="col-md-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="0"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                >
                  <div className="wm-1"></div>
                  <h6>Telegram</h6>

                  <p>
                    <a
                      className="text-decoration-none text-reset"
                      href="https://riddick6600.t.me/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      riddick6600.t.me
                    </a>
                  </p>
                </div>
                <div
                  className="col-md-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="0"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                >
                  <div className="wm-1"></div>
                  <h6>Email</h6>
                  <p>
                    <a
                      className="text-decoration-none text-reset"
                      href="mailto:riddick6600@mail.ru"
                    >
                      riddick6600@mail.ru
                    </a>
                  </p>
                </div>
                <div
                  className="col-md-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="0"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                >
                  <div className="wm-1"></div>
                  <h6>CV</h6>
                  <p>
                    <a
                      className="text-decoration-none text-reset"
                      href="https://hh.ru/resume/b03e7570ff0130f6b30039ed1f4e7069433448"
                      target="_blank"
                      rel="noreferrer"
                    >
                      hh.ru
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default contact;
