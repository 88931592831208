import React from "react";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { TagCloud } from "react-tagcloud";
// import AnimatedProgressProvider from "../layout/AnimatedProgressProvider";

import AOS from "aos";
AOS.init();

const tagsData = [
  { value: "HTML/CSS", count: 90 },
  { value: "JavaScript", count: 80 },
  { value: "Typescript", count: 60 },
  { value: "React", count: 80 },
  { value: "PHP", count: 40 },
  { value: "NodeJS", count: 75 },
  { value: "Express.js", count: 60 },
  { value: "MongoDB", count: 60 },
  { value: "SQL", count: 60 },
  { value: "High Load", count: 40 },
  { value: "Big Data", count: 30 },
  { value: "Linux", count: 70 },
  { value: "Docker", count: 40 },
  { value: "Kubernetes", count: 10 },
  { value: "CI/CD", count: 50 },
  { value: "Blockchain", count: 20 },
  { value: "Smart Contracts", count: 30 },
  { value: "Solidity", count: 40 },
  { value: "BPMN", count: 20 },
  { value: "Power Point", count: 70 },
];

const hero = () => {
  return (
    <div className="v-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              About Me
            </h2>
            <div
              className="space-border"
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            ></div>
          </div>
          <div
            className="col-md-8 text-center m-auto"
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <p>
              I am 31 years Developer, Husband, Mentor, Psychologist,
              Shareholder, Traveler, Musician.
              <br />
              I have 10 years expirience in web development: E-commerce,
              Startups, Advertising and Development Agencies, Social networks,
              Casino, Fintech.
              <br />I am studying Gestalt Psychology at Moscow Gestalt
              University.
            </p>
          </div>
        </div>
        <div
          className="row text-center"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="col-md-12 text-center">
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              My Technology
            </h2>
            <div
              className="space-border"
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            ></div>
          </div>
          <TagCloud
            className="text-center"
            minSize={10}
            maxSize={32}
            tags={tagsData}
            disableRandomColor
            shuffle={false}
          />
        </div>
      </div>
    </div>
  );
};

export default hero;
