import React from "react";
import Typed from "react-typed";
import AOS from "aos";

AOS.init();

const hero = () => {
  return (
    <div className="v-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h6
              className="color"
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              I Am Deryabin Georgy
            </h6>
            <div className="spacer-20"></div>
            <div
              className="h1_big"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              <Typed
                strings={[
                  "DEVELOPER",
                  "TECH LEAD",
                  "ARCHITECT",
                  "DEVOPS",
                  "MENTOR",
                  "PSYCHOLOGIST",
                  "TRAVELER",
                  "SHAREHOLDER",
                  "CRYPTO ENTHUSIAST",
                  "BUSINESSMAN",
                  "MUSICIAN",
                ]}
                typeSpeed={80}
                backSpeed={80}
                loop
              />
            </div>
            <ul
              className="list_location"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              <li>
                <span>WEB</span>10 years expirience
              </li>
              <li>
                <span>Blockchain</span>Explore web 3.0 & Smart Contracts
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
